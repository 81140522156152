import React, { useContext } from 'react'
import { Grid, Button, Typography, withStyles } from '@material-ui/core'
import {
  useAuthorizedQuery,
  AuthContext,
  PaddedContainer,
  SectionTitle,
} from 'gatsby-components'
import { Link } from 'gatsby'
import { Redirect } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { get, flattenDeep } from 'lodash'

import { getAssessmentPartData, getUser } from '../queries'
import AssessmentScoringHeader from '../components/AssessmentScoringHeader'
import AssessmentPillarScoring from '../components/AssessmentPillarScoring'
import { getAssessmentId } from '../utils/url'
import FileList from '../components/FileList'
import CriterionPartHeader from '../components/CriterionPartHeader'
import CriterionPartTable from '../components/CriterionPartTable'
import CriterionPartsPagination from '../components/CriterionPartsPagination'
import CriterionPartFeedbackTable from '../components/CriterionPartFeedbackTable'
import AssessmentPillars from '../components/AssessmentPillars'
import SEO from '../components/SEO'
import {
  assessmentInProgress,
  assessmentSubmitted,
} from '../utils/assessment-status'
import { filterOldScores } from '../utils/filter-old-scores'
import { getAssessmentParts } from 'efqm-theme/assessments/getAssessmentParts'
import useAuthorizedWatch from '../hooks/useAuthorizedWatch'
import { limits } from '../config'

const criterionPillarsLimits = limits.criterion.pillars

function CriterionPartTemplate({
  theme,
  classes,
  location,
  pageContext: {
    partNumber,
    part: contextPart,
    pillar: contextPillar,
    criterion: contextCriterion,
    assessment: contextAssessment,
    pillarColor,
    pillarColors,
    criteriaList: criteriaListContext,
    links: partsLinks,
  },
}) {
  const { isAuthInitialized, getUserTokenData } = useContext(AuthContext)
  const { isAuthenticated } = getUserTokenData()
  const { t, i18n } = useTranslation()
  const { assessment, pillar, criterion, part } = getAssessmentParts(
    contextAssessment.key,
    i18n,
    contextPillar,
    contextCriterion,
    contextPart
  )

  const criteriaList = (function() {
    const translatedNameList = flattenDeep(
      assessment.matrixType === 'basic'
        ? assessment.pillars.map(pillar =>
            pillar.criteria.map(criteria => criteria.name)
          )
        : assessment.pillars.map(pillar =>
            pillar.criteria.map(criteria =>
              criteria.parts.map(part => get(part, 'tables[0].name'))
            )
          )
    )

    const flatCriteriaListContext = flattenDeep(criteriaListContext)

    translatedNameList.forEach((name, i) => {
      flatCriteriaListContext[i].name = name
    })

    return flatCriteriaListContext
  })()

  // Get most specific available definition or default from loaded JSON
  const columnsDef = criterion.columns || pillar.columns || assessment.columns
  const feedbackTablesDef =
    part.feedbackTables ||
    criterion.feedbackTables ||
    pillar.feedbackTables ||
    assessment.feedbackTables
  const scoringDef = pillar.scoring || assessment.scoring
  const scoringRules = pillar.scoringRules || assessment.scoringRules || {}

  const assessmentId = getAssessmentId(location)
  const { isContributor, isAssessor, userId } = getUserTokenData(assessmentId)
  // TODO: Currently, this query just reloads data already entirely present in
  // the user summary; this should be reviewed to see whether the user summary
  // dataset should be reduced (perhaps even to just the user id).
  const { data: user } = useAuthorizedQuery(
    getUser,
    { id: userId },
    {
      onPreFetch: variables => !!variables.id,
      onFetch: data => get(data, 'user.0'),
    }
  )

  const {
    loading,
    isPreFetch,
    fetchedTimestamp,
    error,
    data: assessmentData,
    refetch: fetchAssessmentPartData,
  } = useAuthorizedWatch(
    getAssessmentPartData,
    {
      id: assessmentId,
      pillarKey: pillar.key,
      criterionKey: criterion.key,
      partNumber,
    },
    {
      onPreFetch: variables => !!variables.id,
      onFetch: data => filterOldScores(assessment, data.assessment_by_pk),
    }
  )

  if (isAuthInitialized && !isAuthenticated) {
    return <Redirect to="/auth" noThrow />
  }

  if (error) {
    return t('Error')
  }

  if ((loading && isPreFetch) || !assessmentData || !user) {
    return (
      <div className={classes.root} data-testid="criterion-part">
        <PaddedContainer className={classes.paddedContainer}>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <Typography variant="h3">{t('Loading...')}</Typography>
            </Grid>
          </Grid>
        </PaddedContainer>
      </div>
    )
  }

  const canEditTablesAndUpload =
    isContributor && assessmentInProgress(assessmentData)

  const canEditFeedbackAndScoring =
    isAssessor && assessmentSubmitted(assessmentData)

  return (
    <div className={classes.root} data-testid="criterion-part">
      <SEO title={t(criterion.name)} />
      <PaddedContainer className={classes.paddedContainer}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item>
            <Button
              className={classes.backButton}
              component={Link}
              to={`/assessment/${assessment.key}#${assessmentId}`}
              variant="text"
              color="secondary"
            >
              ◀ {t('Assessment overview')}
            </Button>
          </Grid>
          <Grid item xs />
          <Grid item>
            <FileList
              assessmentId={assessmentId}
              userId={userId}
              pillar={pillar}
              criterion={criterion}
              partNumber={partNumber}
              files={assessmentData.files}
              canUpload={
                assessmentData?.files?.length <
                  criterionPillarsLimits[pillar.key][criterion.key].uploads &&
                canEditTablesAndUpload
              }
              canDelete={canEditTablesAndUpload}
              onUploadComplete={fetchAssessmentPartData}
              fileSize={
                criterionPillarsLimits[pillar.key][criterion.key].fileSize
              }
            />
          </Grid>
        </Grid>
        <div className={classes.section}>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <SectionTitle barColor={pillarColor}>
                <Link to={`/assessment/${assessment.key}#${assessmentId}`}>
                  {t(pillar.name)}
                </Link>{' '}
                <span style={{ color: pillarColor }}>▶</span>{' '}
                <Link
                  to={`/assessment/${assessment.key}/${pillar.key}/${criterion.key}#${assessmentId}`}
                >
                  {t(criterion.name)}
                </Link>
              </SectionTitle>
            </Grid>
          </Grid>
        </div>
        {part.tables.map((table, tableIndex) => (
          <CriterionPartTable
            tableDef={table}
            columnsDef={table.columns || columnsDef}
            key={table.key}
            assessmentTables={assessmentData.tables}
            tablesFetchedTimestamp={fetchedTimestamp}
            assessmentId={assessmentId}
            criterionKey={criterion.key}
            pillarKey={pillar.key}
            limits={criterionPillarsLimits[pillar.key][criterion.key]}
            partNumber={partNumber}
            canEdit={canEditTablesAndUpload}
            criteriaList={criteriaList}
            criterion={criterion}
            matrixType={assessment.matrixType}
            hideHeaderTitle={
              assessment.matrixType !== 'basic' &&
              criterion.parts.length <= 1 &&
              !assessment.lensVersionOf
            }
            paginationNode={
              tableIndex === 0 &&
              assessment.matrixType !== 'basic' && (
                <CriterionPartsPagination
                  assessmentId={assessmentId}
                  currentPartNumber={partNumber}
                  parts={criterion.parts}
                  links={partsLinks}
                  linkComponent={Link}
                />
              )
            }
          />
        ))}
        <div className={classes.section}>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <SectionTitle barColor={pillarColor}>
                {t('To be completed by assessors')}
              </SectionTitle>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
          <CriterionPartHeader
            helpContent={t(
              assessment.matrixType === 'basic'
                ? criterion.basicGuidance
                : part.tables[0].guidance
            )}
            title={t(
              assessment.matrixType === 'basic'
                ? criterion.name
                : part.tables[0].name
            )}
            buttonLabel={t('guidance')}
            hideTitle={
              assessment.matrixType !== 'basic' &&
              criterion.parts.length <= 1 &&
              !assessment.lensVersionOf
            }
            guidanceFile={part.tables[0].guidanceFile}
            paginationNode={
              assessment.matrixType !== 'basic' && (
                <CriterionPartsPagination
                  assessmentId={assessmentId}
                  currentPartNumber={partNumber}
                  parts={criterion.parts}
                  links={partsLinks}
                  linkComponent={Link}
                />
              )
            }
          />
        </Grid>
        {feedbackTablesDef.map(tableDef => (
          <CriterionPartFeedbackTable
            tableDef={tableDef}
            key={tableDef.key}
            assessmentFeedbackTables={assessmentData.feedbackTables}
            tablesFetchedTimestamp={fetchedTimestamp}
            assessmentId={assessmentId}
            criterionKey={criterion.key}
            pillarKey={pillar.key}
            partNumber={partNumber}
            canEdit={canEditFeedbackAndScoring}
            user={user}
          />
        ))}
        <AssessmentPillars
          assessment={assessment}
          assessmentData={assessmentData}
          pillarColors={pillarColors}
        />
      </PaddedContainer>
      <div className={classes.scoringSection}>
        <PaddedContainer>
          <AssessmentScoringHeader assessmentDef={assessment} />
          <AssessmentPillarScoring
            assessmentId={assessmentId}
            assessment={assessment}
            assessmentData={assessmentData}
            pillar={pillar}
            scoringDef={scoringDef}
            scoringRules={scoringRules}
            criterion={criterion}
            partNumber={partNumber}
            canEdit={canEditFeedbackAndScoring}
          />
        </PaddedContainer>
      </div>
    </div>
  )
}

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  paddedContainer: {
    flex: 1,
  },
  backButton: {
    paddingLeft: 0,
  },
  section: {
    margin: theme.spacing(3, 0),
  },
  scoringSection: {
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(3, 0),

    // Work around MUI 3.x sliders bug causing viewport overflow
    // see https://github.com/mui-org/material-ui/issues/13455
    overflow: 'hidden',
  },
  '@global': {
    footer: {
      display: 'none',
    },
  },
})

export default withStyles(styles, { withTheme: true })(CriterionPartTemplate)
