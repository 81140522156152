import React from 'react'
import T from 'prop-types'
import { withStyles, Grid, Typography } from '@material-ui/core'

function CriterionPartsPagination({
  classes,
  currentPartNumber,
  assessmentId,
  parts,
  links,
  linkComponent,
}) {
  return (
    <Grid container spacing={2} justify="flex-end" alignItems="center">
      {parts.map(({ tables: [{ name }] }, partIndex) => (
        <Grid key={`item-${partIndex}`} item>
          {currentPartNumber === partIndex + 1 ? (
            <Typography className={classes.current} variant="h3">
              {name.split(':')[0]}
            </Typography>
          ) : (
            <Typography
              color="secondary"
              component={linkComponent}
              to={`${links[partIndex]}#${assessmentId}`}
              variant="h3"
            >
              {name.split(':')[0]}
            </Typography>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

CriterionPartsPagination.propTypes = {
  classes: T.object.isRequired,
  currentPartNumber: T.number.isRequired,
  assessmentId: T.number.isRequired,
  parts: T.array.isRequired,
  links: T.array.isRequired,
  linkComponent: T.any,
}

const styles = theme => ({
  current: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.colorDefinitions.white,
  },
})

export default withStyles(styles, { withTheme: true })(CriterionPartsPagination)
