import React, { useState } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { useMutation } from 'graphql-hooks'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'

import { uploadFile } from '../utils/storage'
import { createFileUploadMutation } from '../queries'
import UploadButton from './UploadButton'
import FileItem from './FileItem'
import { fileType } from '../prop-types'

function FileList({
  theme,
  classes,
  assessmentId,
  pillar,
  criterion,
  partNumber,
  userId,
  files,
  canUpload,
  canDelete,
  onUploadComplete,
  isFeedbackReportFile,
  alignItems,
  hideHeader,
  fileSize,
}) {
  const [createFileUpload] = useMutation(createFileUploadMutation)
  const { t } = useTranslation()
  const [uploading, setUploading] = useState(false)

  async function createNewFileUpload(file, s3Key) {
    const { error } = await createFileUpload({
      variables: {
        fileUploadData: {
          user_id: userId,
          assessment_id: assessmentId,
          pillar_key: pillar ? pillar.key : null,
          criterion_key: criterion ? criterion.key : null,
          part_number: partNumber,
          file_name: file.name,
          file_size: file.size,
          s3_key: s3Key,
          is_feedback_report_file: isFeedbackReportFile,
        },
      },
    })

    if (error) throw error
  }

  async function handleFileUpload(file) {
    setUploading(true)
    const { key: s3Key } = await uploadFile(file, assessmentId)
    await createNewFileUpload(file, s3Key)
    setUploading(false)

    onUploadComplete()
  }

  const listFiles = files => {
    const list = []

    for (let i = 0; i < files.length; i += 2) {
      list.push(
        <Grid item>
          <div className={classes.fileItemWrapper}>
            {i + 1 < files.length && (
              <FileItem
                file={files[i + 1]}
                canDelete={canDelete}
                onDeleteComplete={onUploadComplete}
              />
            )}
            <FileItem
              file={files[i]}
              canDelete={canDelete}
              onDeleteComplete={onUploadComplete}
            />
          </div>
        </Grid>
      )
    }

    return list
  }

  const listFilesFeedbackReport = files =>
    files.map(file => (
      <Grid item key={file.s3_key}>
        <div className={classes.fileItemWrapper}>
          <FileItem
            file={file}
            canDelete={canDelete}
            onDeleteComplete={onUploadComplete}
          />
        </div>
      </Grid>
    ))

  return (
    <Grid
      container
      alignItems={alignItems ? alignItems : 'flex-end'}
      direction="column"
      spacing={2}
      wrap="nowrap"
      className={classes.fileList}
    >
      <Grid item>
        {!hideHeader && (
          <div className={classes.fileItemWrapper}>
            <Typography
              variant="h4"
              color="textSecondary"
              className={classes.text}
            >
              {t('Supporting documentation')}
            </Typography>
          </div>
        )}
      </Grid>
      {isFeedbackReportFile ? listFilesFeedbackReport(files) : listFiles(files)}
      {canUpload && (
        <Grid item>
          <UploadButton
            onFileSelected={handleFileUpload}
            color="secondary"
            variant="outlined"
            disabled={uploading}
            fileSize={fileSize}
          >
            {t('Upload')}
          </UploadButton>
        </Grid>
      )}
    </Grid>
  )
}

FileList.propTypes = {
  theme: T.object.isRequired,
  assessmentId: T.number.isRequired,
  pillar: T.object.isRequired,
  criterion: T.object.isRequired,
  partNumber: T.number,
  userId: T.any.isRequired,
  files: T.arrayOf(fileType).isRequired,
  canUpload: T.bool.isRequired,
  canDelete: T.bool.isRequired,
  onUploadComplete: T.func.isRequired,
  isFeedbackReportFile: T.bool,
  alignItems: T.string,
  hideHeader: T.bool,
  fileSize: T.number,
}

const styles = theme => ({
  fileList: {
    float: 'right',
  },
  fileItemWrapper: {
    display: 'flex',
  },
  text: {
    paddingLeft: theme.spacing(1),
  },
})

export default withStyles(styles, { withTheme: true })(FileList)
