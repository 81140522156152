import React, { useState, useEffect } from 'react'
import T from 'prop-types'
import {
  Grid,
  Button,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUpRounded'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownRounded'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'graphql-hooks'
import get from 'lodash/get'
import classnames from 'classnames'
import SaveChip from './SaveChip'

import { Form } from 'formik'

import {
  insertAssessmentTableDataMutation,
  updateAssessmentTableDataMutation,
  deleteAssessmentTableRowMutation,
} from '../queries'

import CriterionPartInput from './CriterionPartInput'
import CriterionPartHeader from './CriterionPartHeader'
import AutoSaveWatchFormik from './AutoSaveWatchFormik'

function getEmptyTableRow(columnsDef) {
  return columnsDef.reduce(
    (initialValues, { key, type }) => ({
      ...initialValues,
      [key]: type === 'link' ? [] : '',
    }),
    {}
  )
}

function getExistingTableData(assessmentTables, tableDef) {
  return (
    assessmentTables &&
    assessmentTables.find(({ table_key }) => table_key === tableDef.key)
  )
}

function getReturnedTableData(queryAction, result, tableId) {
  if (result.error) throw result.error
  const err = msg => {
    throw new Error(`Return from ${queryAction} ${msg}`)
  }

  const expectedProps = `data.${queryAction}.returning`
  const returning = get(result, expectedProps)

  if (!returning) err(`lacks properties ${expectedProps}`)

  const table = tableId
    ? returning.find(table => table.id === tableId)
    : returning[0]

  if (!table) err(`lacks table with id ${tableId}`)
  if (!table.table_values) err(`lacks .table_values on table id ${tableId}`)

  return table
}

function CriterionPartTable({
  theme,
  classes,
  tableDef,
  columnsDef,
  assessmentTables,
  tablesFetchedTimestamp,
  assessmentId,
  partNumber,
  criterionKey,
  pillarKey,
  limits,
  canEdit,
  paginationNode,
  criteriaList,
  matrixType,
  criterion,
  hideHeaderTitle,
}) {
  const { t, i18n } = useTranslation()
  const tableData = getExistingTableData(assessmentTables, tableDef)

  const tableIdOrNull = tableData ? tableData.id : null
  const [tableId, setTableId] = useState(tableIdOrNull)
  const rowsOrDefault = tableData ? tableData.table_values : []
  const [tableRows, setTableRows] = useState(rowsOrDefault)

  // Update the form state and id anytime the table data changes (i.e. due to watches in the DB).
  // This is required since this component stores table data from props in its state.
  useEffect(() => {
    setTableId(tableIdOrNull)
    setTableRows(rowsOrDefault)
  }, [tableIdOrNull, JSON.stringify(rowsOrDefault)])

  const [insertTableData] = useMutation(insertAssessmentTableDataMutation)
  const [updateTableData] = useMutation(updateAssessmentTableDataMutation)
  const [deleteTableRow] = useMutation(deleteAssessmentTableRowMutation)

  async function handleDeleteTableRow(rowIndex) {
    const result = await deleteTableRow({
      variables: {
        id: tableId,
        rowIndex,
      },
    })

    const { table_values: returnedRows } = getReturnedTableData(
      'update_assessment_table',
      result,
      tableId
    )
    setTableRows(returnedRows)
  }

  async function handleSaveTable(rowIndex, rowValues, { setSubmitting }) {
    if (tableId) {
      const modifiedRows = [...tableRows]
      modifiedRows[rowIndex] = rowValues

      const result = await updateTableData({
        variables: {
          id: tableId,
          tableValues: modifiedRows,
        },
      })
      // Ensure what was written to db and returned is what is shown
      const { table_values: returnedRows } = getReturnedTableData(
        'update_assessment_table',
        result,
        tableId
      )

      // Update this row only to not erase user's unsaved work on other rows
      modifiedRows[rowIndex] = returnedRows[rowIndex]

      setTableRows(modifiedRows)
      setSubmitting(false)
    } else {
      const result = await insertTableData({
        variables: {
          assessmentId,
          pillarKey,
          criterionKey,
          partNumber,
          tableKey: tableDef.key,
          tableValues: [rowValues],
        },
      })

      const { id, table_values: returnedRows } = getReturnedTableData(
        'insert_assessment_table',
        result,
        tableId
      )

      setTableId(id)
      setTableRows(returnedRows)
    }
  }

  async function handleMoveTableRow(rowIndex, incr) {
    const modifiedRows = [...tableRows]

    // swap items
    const tempRow = modifiedRows[rowIndex]
    modifiedRows[rowIndex] = modifiedRows[rowIndex + incr]
    modifiedRows[rowIndex + incr] = tempRow

    const result = await updateTableData({
      variables: {
        id: tableId,
        tableValues: modifiedRows,
      },
    })
    // Ensure what was written to db and returned is what is shown
    const { table_values: returnedRows } = getReturnedTableData(
      'update_assessment_table',
      result,
      tableId
    )

    // Update this row only to not erase user's unsaved work on other rows
    modifiedRows[rowIndex + incr] = returnedRows[rowIndex + incr]
    modifiedRows[rowIndex] = returnedRows[rowIndex]

    setTableRows(modifiedRows)
  }

  const tables = [...tableRows]
  const hasEmptyRow =
    tableRows.length < limits.items[matrixType === 'basic' ? 0 : 1] &&
    (canEdit || !tables.length)
  if (hasEmptyRow) {
    tables.push(getEmptyTableRow(columnsDef))
  }
  const isDisabledAndEmpty = !canEdit && !tableRows.length

  return (
    <Grid>
      <CriterionPartHeader
        helpContent={t(
          matrixType === 'basic' ? criterion.basicGuidance : tableDef.guidance
        )}
        title={t(matrixType === 'basic' ? criterion.name : tableDef.name)}
        paginationNode={paginationNode}
        buttonLabel={t('guidance')}
        hideTitle={hideHeaderTitle}
        guidanceFile={tableDef.guidanceFile}
      />
      {tables.map((initialValues, rowIndex, { length: totalRows }) => {
        const tableKey = `${tableDef.key}-${rowIndex}`
        return (
          <AutoSaveWatchFormik
            validateOnMount={true}
            initialValues={initialValues}
            initialValuesTimestamp={tablesFetchedTimestamp}
            onSubmit={(values, actions) =>
              handleSaveTable(rowIndex, values, actions)
            }
            key={tableKey}
          >
            {({ values, setFieldValue, saving }) => (
              <Form className={classes.section}>
                <Grid container direction="column" spacing={2}>
                  <Grid item container spacing={1} wrap="nowrap">
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography
                            variant="h4"
                            gutterBottom
                            className={classnames({
                              invisible: rowIndex > 0,
                              [classes.disbledAndEmpty]: isDisabledAndEmpty,
                            })}
                          >
                            {t('ITEM')}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="center"
                          justifyItems="center"
                        >
                          {canEdit &&
                            !(hasEmptyRow && rowIndex === totalRows - 1) && (
                              <IconButton
                                size="small"
                                onClick={() => handleMoveTableRow(rowIndex, -1)}
                                disabled={rowIndex === 0}
                              >
                                <ArrowDropUpIcon />
                              </IconButton>
                            )}
                          <Typography
                            variant="h3"
                            color="primary"
                            className={classnames({
                              [classes.disbledAndEmpty]: isDisabledAndEmpty,
                            })}
                          >
                            {rowIndex + 1}
                          </Typography>
                          {canEdit &&
                            !(hasEmptyRow && rowIndex === totalRows - 1) && (
                              <IconButton
                                size="small"
                                onClick={() => handleMoveTableRow(rowIndex, +1)}
                                disabled={
                                  rowIndex === totalRows - (hasEmptyRow ? 2 : 1)
                                }
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        className={classes.itemBorderContainer}
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            variant="h4"
                            gutterBottom
                            className={classes.invisible}
                          >
                            {rowIndex}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          className={classnames(classes.itemBorder, {
                            [classes.itemBorderActive]:
                              rowIndex === totalRows - 1,
                            [classes.itemBorderDisabled]: isDisabledAndEmpty,
                          })}
                        >
                          &nbsp;
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      {columnsDef.length % 2 === 0 ? (
                        <Grid container spacing={2} alignItems="flex-start">
                          {columnsDef.map(column => {
                            const inputKey = `${tableKey}-${column.key}`

                            return (
                              <Grid item xs={4} key={inputKey}>
                                <CriterionPartInput
                                  key={inputKey}
                                  inputKey={inputKey}
                                  column={column}
                                  values={values}
                                  canEdit={canEdit}
                                  criteriaList={criteriaList}
                                  assessmentId={assessmentId}
                                  setFieldValue={setFieldValue}
                                  isDisabledAndEmpty={isDisabledAndEmpty}
                                  lang={i18n.language}
                                  maxWordCount={limits.words}
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      ) : (
                        <Grid container spacing={2} alignItems="flex-start">
                          <Grid
                            container
                            item
                            spacing={2}
                            xs={8}
                            alignItems="flex-start"
                          >
                            {columnsDef
                              .slice(0, columnsDef.length - 1)
                              .map(column => {
                                const inputKey = `${tableKey}-${column.key}`

                                return (
                                  <Grid item xs={6} key={inputKey}>
                                    <CriterionPartInput
                                      key={inputKey}
                                      inputKey={inputKey}
                                      column={column}
                                      values={values}
                                      canEdit={canEdit}
                                      criteriaList={criteriaList}
                                      assessmentId={assessmentId}
                                      setFieldValue={setFieldValue}
                                      isDisabledAndEmpty={isDisabledAndEmpty}
                                      lang={i18n.language}
                                      maxWordCount={limits.words}
                                    />
                                  </Grid>
                                )
                              })}
                          </Grid>
                          <Grid container item xs={4}>
                            <Grid
                              item
                              xs={12}
                              key={`${tableKey}-${
                                columnsDef[columnsDef.length - 1].key
                              }`}
                            >
                              <CriterionPartInput
                                key={`${tableKey}-${
                                  columnsDef[columnsDef.length - 1].key
                                }`}
                                inputKey={`${tableKey}-${
                                  columnsDef[columnsDef.length - 1].key
                                }`}
                                column={columnsDef[columnsDef.length - 1]}
                                values={values}
                                canEdit={canEdit}
                                criteriaList={criteriaList}
                                assessmentId={assessmentId}
                                setFieldValue={setFieldValue}
                                isDisabledAndEmpty={isDisabledAndEmpty}
                                lang={i18n.language}
                                className={classes.largerField}
                                maxWordCount={limits.words}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {canEdit && (
                    <Grid item container spacing={2} justify="flex-end">
                      {(rowIndex !== tableRows.length || saving) && (
                        <Grid item>
                          <div className={classes.saveStatus}>
                            <SaveChip dirty={saving} />
                          </div>
                        </Grid>
                      )}
                      {rowIndex !== tableRows.length && (
                        <Grid item>
                          <Button
                            onClick={() => handleDeleteTableRow(rowIndex)}
                            variant="outlined"
                            color="secondary"
                            disabled={!canEdit}
                          >
                            {t('Remove')}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Form>
            )}
          </AutoSaveWatchFormik>
        )
      })}
    </Grid>
  )
}

CriterionPartTable.propTypes = {
  theme: T.object.isRequired,
  classes: T.object.isRequired,
  tableDef: T.object.isRequired,
  assessmentTables: T.array.isRequired,
  assessmentId: T.number.isRequired,
  partNumber: T.number.isRequired,
  criterionKey: T.string.isRequired,
  pillarKey: T.string.isRequired,
  paginationNode: T.node,
  canEdit: T.bool.isRequired,
  matrixType: T.string,
  criterion: T.object,
  hideHeaderTitle: T.bool,
}
const styles = theme => ({
  section: {
    margin: theme.spacing(3, 0),
  },
  invisible: {
    visibility: 'hidden',
  },
  itemBorderContainer: {
    height: '100%',
  },
  itemBorder: {
    width: theme.spacing(0.5),
    backgroundColor: theme.palette.background.dark,
    flex: 1,
  },
  itemBorderActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  itemBorderDisabled: {
    backgroundColor: theme.palette.background.light,
  },
  disbledAndEmpty: {
    color: theme.palette.background.dark,
  },
  saveStatus: {
    marginTop: '4px',
  },
})

export default withStyles(styles, { withTheme: true })(CriterionPartTable)
